.table-head-row th:first-child {
  padding-left: 8px;
}

.table-head-row th:last-child {
  padding-right: 8px;
}

.table-head-row th {
  padding-bottom: 16px;
}

.table-row-item td:first-child {
  padding-left: 16px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-row-item td:last-child {
  padding-right: 16px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
