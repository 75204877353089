.live {
  border-radius: 50%;
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  height: 24px;
  width: 24px;
  transform: scale(1);
  animation: pulse-red 1.5s infinite;
  margin: 6px;
}

.live-green {
  border-radius: 50%;
  background: #72f238;
  box-shadow: 0 0 0 0 #72f238;
  height: 16px;
  width: 16px;
  transform: scale(1);
  animation: pulse-green 1.5s infinite;
  margin: 6px;
}

.smallLive {
  border-radius: 50%;
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  height: 12px;
  width: 12px;
  transform: scale(1);
  animation: pulse-red 1.5s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(24, 196, 32, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
