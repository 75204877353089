.active {
  transform: translateX(0%);
}

#alert-progress.active {
  animation: progress 4.9s linear forwards;
}

@keyframes progress {
  100% {
    width: 0%;
  }
}
