.scroll::-webkit-scrollbar {
  background-color: transparent;
  max-width: 6px;
  max-height: 6px;
}

.scroll:hover::-webkit-scrollbar {
  background-color: transparent;
  max-width: 6px;
  max-height: 6px;
}

.scroll::-webkit-scrollbar-thumb {
  background: 'transparent';
  border-radius: 0.8em;
}

.scroll:hover::-webkit-scrollbar-thumb {
  background-color: #465a72;
  opacity: 0.5;
}
